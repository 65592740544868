import React, { FC } from 'react';
import { ButtonIcon } from 'components/Layout/Buttons';

import * as S from './styled';
import theme from 'theme';

type PropsType = {
  isOpen: boolean;
  title: string;
  titleColor?: string;
  children: React.ReactNode;
  onRequestClose: () => void;
  onAfterClose?: () => void;
  withHeader?: boolean;
};

export const Modal: FC<PropsType> = ({
  title,
  isOpen,
  titleColor,
  withHeader = true,
  onRequestClose,
  children,
  ...restProps
}) => (
  <S.Modal
    isOpen={isOpen}
    contentLabel={title}
    onRequestClose={onRequestClose}
    shouldCloseOnOverlayClick
    ariaHideApp={false}
    {...restProps}
  >
    {withHeader && (
      <S.Header titleColor={titleColor || theme.primaryBlack}>
        {title}
        <ButtonIcon onClick={onRequestClose}>
          <S.IconClose />
        </ButtonIcon>
      </S.Header>
    )}
    <S.Content>{children}</S.Content>
  </S.Modal>
);

Modal.defaultProps = {
  withHeader: true,
  onAfterClose: () => {},
  titleColor: theme.primaryBlack,
};
