import styled from 'styled-components';

import { ToolbarContainer } from 'components/Layout/Containers';
import { ButtonIcon } from 'components/Layout/Buttons';

const Container = styled(ToolbarContainer)`
  height: 50px;
  justify-content: center !important;
`;

const Value = styled.span`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const IconButton = styled(ButtonIcon)`
  min-width: 48px;
`;

const ModalContainer = styled.div`
  min-width: 400px;
`;

const MonthContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2vw;
  margin: 20px 5px;
`;

const MonthButton = styled.button`
  font-size: 1rem;
  padding: 0.5em;
  background: gold;
  text-align: center;
  padding: 10px 30px;
  background-color: #fff;
  align-items: center;
  outline: none;
  border: none;
  text-transform: uppercase;
  &:hover {
    background-color: #c4e4e1;
  }
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.primaryQueue};
  color: ${({ theme }) => theme.primaryWhite};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`;

const CurrentMonth = styled.div`
  font-size: 25px;
  font-weight: 300;
`;

const TasksCount = styled.div`
  background-color: #c4e4e1;
  color: ${({ theme }) => theme.primaryRed};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  height: 34px;
  width: 70px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectContainer = styled.div`
  margin: 10px 0px 30px 40px;
`;

const Select = styled.select`
  width: 100px;
  height: 30px;

  appearance: none;
  font-size: 1rem;
  padding: 0 10px;
  background-color: ${({ theme }) => theme.lightGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 10px;
  color: #000;
  cursor: pointer;

  position: absolute;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  overflow-y: auto;
  transition: 0.5s ease;
`;

const Arrow = styled.span`
  position: relative;
  bottom: -18px;
  left: 74px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
  transition: transform ease-in-out 0.3s;
`;

const First = styled.div`
  flex: 1;
`;

const Second = styled.div`
  margin-right: 10px;
`;

const Text = styled.p`
  font-size: 13px;
`;

export {
  Container,
  Value,
  IconButton,
  MonthContainer,
  MonthButton,
  Header,
  CurrentMonth,
  TasksCount,
  ModalContainer,
  SelectContainer,
  Select,
  First,
  Second,
  Text,
  Arrow,
};
