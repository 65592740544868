const groupBy = (list, key) => {
  if (!list || !Array.isArray(list)) {
    return [];
  }

  const formattedData = list.reduce((accum, item) => {
    const keyValue = item[key];

    if (!accum[keyValue]) {
      accum[keyValue] = { items: [] };
    }

    accum[keyValue].items.push(item);

    return accum;
  }, {});

  return Object.entries(formattedData).map(([groupName, groupData]) => ({
    [key]: groupName,
    items: groupData.items,
  }));
};

const sortByExecutionDateAndItemSequence = arr =>
  [...arr].sort((a, b) => a.ExecutionDate - b.ExecutionDate || a.ItemSequence - b.ItemSequence);

export { groupBy, sortByExecutionDateAndItemSequence };
