import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import FlatButton from 'material-ui/FlatButton';
import { actions as tasksActions } from 'redux/tasks';
import { TaskNotesPhotos } from 'configs/propTypes';
import { ButtonIcon } from 'components/Layout/Buttons';
import ConfirmDialog from 'components/Dialogs/Confirm';
import PhotosModal from './PhotosModal';
import Section from './Section/';
import NotifyForm from './NotifyForm';
import BaseDialog from 'components/Dialogs/Base';
import EmailDialog from './EmailDialog';

import theme from 'theme';
import * as S from './styled';

const TaskWithPartsDetails = props => {
  const {
    updateAssignedTaskList,
    OriginatingTaskName,
    AssigneeName,
    AssigneeId,
    Manager,
    SiteName,
    UnitName,
    ItemName,
    TeamName,
    InstructionalNotes,
    Id,
    taskParts,
    PartResolution: partResolution,
    RuleType: ruleType,
    Exceptions: exceptions,
    notesPhotos,
    isDialogWithParts,
  } = props;
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isWarningDialogOpened, setIsWarningDialogOpened] = useState(false);
  const [isCompletionDialogOpened, setIsCompletionDialogOpened] = useState(false);
  const [isPhotosModalVisible, setIsPhotosModalVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedManager, setSelectedManager] = useState({});
  const [selectedAssignee, setSelectedAssignee] = useState({});

  const openWarningDialog = () => setIsWarningDialogOpened(true);

  const closeWarningDialog = () => setIsWarningDialogOpened(false);

  // eslint-disable-next-line no-shadow
  const openEmailDialog = ({ manager, assignee, message }) => {
    setIsEmailDialogOpen(true);
    setMessage(message);
    setSelectedManager(manager);
    setSelectedAssignee(assignee);
  };

  const closeEmailDialog = () => setIsEmailDialogOpen(false);

  const viewPhotos = () => setIsPhotosModalVisible(true);

  const closePhotosModals = () => setIsPhotosModalVisible(false);

  const openCompletionDialog = () => setIsCompletionDialogOpened(true);

  const closeCompletionDialog = () => setIsCompletionDialogOpened(false);

  const onSubmit = values => {
    if (!values.manager && !values.assignee) {
      openWarningDialog();
      return;
    }

    const managerValue = values.manager ? { name: Manager?.Name, managerId: Manager?.Id } : {};
    const assigneeValue = values.assignee ? { name: AssigneeName, assigneeId: AssigneeId } : {};

    openEmailDialog({
      manager: managerValue,
      assignee: assigneeValue,
      message: values.message,
    });
  };

  const sendEmail = () => {
    dispatchEvent(
      tasksActions.sendTaskInfoEmailRequest({
        taskId: Id,
        recepientsId: AssigneeId && Manager?.Id ? [AssigneeId, Manager?.Id] : [AssigneeId || Manager?.Id],
        messageText: message,
      }),
    );

    closeEmailDialog();
  };

  const completeTask = () => {
    dispatchEvent(
      tasksActions.saveTaskRequest({
        id: Id,
        ruleType: 'ForceComplete',
      }),
    );
    closeCompletionDialog();
    updateAssignedTaskList();
  };

  return (
    <>
      <S.TaskWithPartDetails>
        <S.Head>
          <li>Task: {ruleType}</li>
          <li>Responsible: {TeamName || AssigneeName || 'Pick-Up'}</li>
          <li>Manager: {Manager?.Name}</li>
        </S.Head>
        <Section title="Location Info">
          <S.List>
            <S.LocationItem>
              <S.Item>Site:</S.Item>
              {SiteName}
            </S.LocationItem>
            <S.LocationItem>
              <S.Item>Unit:</S.Item>
              {UnitName}
            </S.LocationItem>
            <S.LocationItem>
              <S.Item>Item:</S.Item>
              {ItemName}
            </S.LocationItem>
          </S.List>
        </Section>
        {!isDialogWithParts && (
          <>
            <Section title="Instructions/Notes">
              <S.List>
                <S.LocationItem>
                  <S.Item>Task Instructions:</S.Item>
                  {OriginatingTaskName}
                </S.LocationItem>
                <S.LocationItem>
                  <S.Item>Task Notes:</S.Item>
                  {InstructionalNotes}
                </S.LocationItem>
              </S.List>
            </Section>
          </>
        )}
        {isDialogWithParts && (
          <>
            <Section warning={1} title="Parts checked for Attention">
              <S.PartsItem>Part ID</S.PartsItem>
              <S.PartsItem>Part Name</S.PartsItem>
              {taskParts.map(({ myPartId, myPartName }, index) => (
                <Fragment key={`${myPartId}-${index}`}>
                  <S.PartsItem>
                    <S.PartsValue>{myPartId}</S.PartsValue>
                  </S.PartsItem>
                  <S.PartsItem>
                    <S.PartsValue>{myPartName}</S.PartsValue>
                  </S.PartsItem>
                </Fragment>
              ))}
            </Section>
            <Section warning={1} title="Part issue">
              <p>{partResolution}</p>
            </Section>
          </>
        )}
        <Section warning={1} title="Attention Issue">
          <p>{exceptions}</p>
        </Section>
        {!isDialogWithParts && (
          <S.PhotosBlock>
            {notesPhotos.length ? (
              <>
                Photos:
                <ButtonIcon onClick={viewPhotos}>
                  <S.PhotoIcon />
                </ButtonIcon>
              </>
            ) : (
              <>
                Photos:
                <ButtonIcon tooltip="No Photos" onClick={viewPhotos}>
                  <S.NoPhotoIcon />
                </ButtonIcon>
              </>
            )}
          </S.PhotosBlock>
        )}
        <S.NotifyTitle>Notify</S.NotifyTitle>
        <Section title="Message to Assignee(s) ">
          <NotifyForm onSubmit={onSubmit} manager={Manager} assignee={AssigneeName} />
        </Section>
      </S.TaskWithPartDetails>
      <FlatButton fullWidth onClick={openCompletionDialog} style={S.primaryStyles}>
        Close and Complete Task
      </FlatButton>
      <BaseDialog open={isEmailDialogOpen} onRequestClose={closeEmailDialog} title="Message">
        <EmailDialog
          // {...this.props}
          message={message}
          selectedAssignee={selectedAssignee}
          selectedManager={selectedManager}
          handleClose={closeEmailDialog}
          handleSend={sendEmail}
        />
      </BaseDialog>
      <BaseDialog
        open={isWarningDialogOpened}
        onRequestClose={closeWarningDialog}
        title="Warning"
        modalStyles={{ maxWidth: '400px' }}
        titleColor={theme.primaryRed}
      >
        <S.WarningText>Please select someone!</S.WarningText>
        <FlatButton fullWidth onClick={closeWarningDialog} style={S.primaryStyles}>
          OK
        </FlatButton>
      </BaseDialog>

      <ConfirmDialog
        open={isCompletionDialogOpened}
        title="Warning"
        text={
          <S.WarningText>
            This will <strong>remove</strong> and complete the Task
            <br />
            Are you sure ?
          </S.WarningText>
        }
        confirmText="Yes"
        cancelText="Cancel"
        onApprove={completeTask}
        onReject={closeCompletionDialog}
      />
      {isPhotosModalVisible && (
        <PhotosModal photos={notesPhotos} open={isPhotosModalVisible} handleClose={closePhotosModals} parentId={Id} />
      )}
    </>
  );
};

TaskWithPartsDetails.propTypes = {
  OriginatingTaskName: PropTypes.string,
  AssigneeName: PropTypes.string,
  Manager: PropTypes.shape({ Id: PropTypes.string, Name: PropTypes.string }),
  UnitName: PropTypes.string,
  SiteName: PropTypes.string,
  InstructionalNotes: PropTypes.string,
  ItemName: PropTypes.string,
  taskParts: PropTypes.array,
  PartResolution: PropTypes.string,
  Exceptions: PropTypes.string,
  AssigneeId: PropTypes.string,
  Id: PropTypes.string.isRequired,
  updateAssignedTaskList: PropTypes.func.isRequired,
  RuleType: PropTypes.string.isRequired,
  TeamName: PropTypes.string,
  isDialogWithParts: PropTypes.bool.isRequired,
  notesPhotos: TaskNotesPhotos.isRequired,
};

TaskWithPartsDetails.defaultProps = {
  OriginatingTaskName: '',
  AssigneeName: '',
  Manager: null,
  SiteName: '',
  ItemName: '',
  UnitName: '',
  taskParts: [],
  PartResolution: '',
  AssigneeId: '',
  Exceptions: '',
  RuleType: '',
  TeamName: '',
  InstructionalNotes: '',
};

export default TaskWithPartsDetails;
