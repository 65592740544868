import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FlatButton } from 'material-ui';
import { useDispatch } from 'react-redux';
import { progressTasksToggle } from 'configs/toggles';

import { Modal } from 'components/Modal';
import { AlternateCalendarIcon } from 'components/Layout/Icons';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { actions as queueActions } from 'redux/queue';
import * as S from './styled';

const Period = ({ configs, type, startRange, selectedDate, setSelectedDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  if (configs.every(({ key }) => key !== type)) {
    return null;
  }

  const formattedStart = moment(startRange).format('dddd MMM D');
  const formattedEnd = moment(startRange).add(6, 'day').format('dddd MMM D, YYYY');

  const formattedRange = `${formattedStart} - ${formattedEnd}`;
  const currentMonth = moment(selectedDate).format('MMMM yyyy');
  const year = moment(selectedDate).year();

  const handleClick = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const changePathName = newDate => {
    const pathParts = history.location.pathname.split('/');
    pathParts[2] = newDate;
    const newPath = pathParts.join('/');
    history.push(newPath);
  };

  const onYearChangeHandler = event => {
    // eslint-disable-next-line no-shadow
    const year = event.target.value;

    const newYearDate = moment(selectedDate, 'YYYY-MM-DD').year(year).format('YYYY-MM-DD');
    changePathName(newYearDate);
    setSelectedDate(newYearDate);
  };

  const generateYearOptions = () => {
    const startYear = 2000;
    const endYear = 2100;
    const years = [];
    // eslint-disable-next-line no-plusplus, no-shadow
    for (let year = startYear; year <= endYear; year++) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>,
      );
    }
    return years;
  };

  const MONTHS = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };

  const onMonthSelect = month => {
    const newMonthDate = moment(selectedDate, 'YYYY-MM-DD').month(month).format('YYYY-MM-DD');
    changePathName(newMonthDate);
    setSelectedDate(newMonthDate);
  };

  return (
    <S.Container>
      {(type === progressTasksToggle[0].key || type === 'day' || type === 'all') && (
        <S.Value>{startRange.format('dddd | MMMM D, YYYY')}</S.Value>
      )}
      {(type === progressTasksToggle[1].key || type === 'week') && <S.Value>{formattedRange}</S.Value>}
      {type === progressTasksToggle[2].key && (
        <div>
          <FlatButton
            onClick={handleClick}
            label={currentMonth}
            labelStyle={{ textTransform: 'none', fontSize: '18px', fontWeight: 'bolder' }}
            icon={<AlternateCalendarIcon />}
          />

          <Modal isOpen={isOpen} onRequestClose={handleClick} withHeader={false}>
            <S.ModalContainer>
              <S.Header>
                <S.First>
                  <S.Text>Select Month</S.Text>
                  <S.CurrentMonth>{currentMonth}</S.CurrentMonth>
                </S.First>
                <S.Second>
                  <S.Text>Tasks</S.Text>
                  <S.TasksCount>{configs[2].total}</S.TasksCount>
                </S.Second>
              </S.Header>
              <S.SelectContainer>
                <S.Select value={year} onChange={onYearChangeHandler}>
                  <option value={year} disabled>
                    {year}
                  </option>
                  {generateYearOptions()}
                </S.Select>
                <S.Arrow />
              </S.SelectContainer>

              <S.MonthContainer>
                {Object.entries(MONTHS).map(([key, value]) => (
                  <S.MonthButton key={key} onClick={() => onMonthSelect(key)}>
                    {value}
                  </S.MonthButton>
                ))}
              </S.MonthContainer>
              <ActionsContainer gapValue={10}>
                <ActionButton label="Cancel" type="button" onClick={handleClick} />
                <ActionButton
                  isNext
                  label="OK"
                  type="button"
                  onClick={() => {
                    dispatch(queueActions.fetchQueueListRequest({ rangeStart: selectedDate, period: 'month' }));
                    setIsOpen(false);
                  }}
                />
              </ActionsContainer>
            </S.ModalContainer>
          </Modal>
        </div>
      )}
    </S.Container>
  );
};

Period.propTypes = {
  configs: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  startRange: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
};

export default Period;
