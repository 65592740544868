import React from 'react';
import styled from 'styled-components';

import ActionAccountBalance from 'material-ui/svg-icons/action/account-balance';
import ActionAssessment from 'material-ui/svg-icons/action/assessment';
import ActionAssignment from 'material-ui/svg-icons/action/assignment';
import ActionAssignmentReturned from 'material-ui/svg-icons/action/assignment-returned';
import AssignmentTurnedIn from 'material-ui/svg-icons/action/assignment-turned-in';
import ActionCameraEnhance from 'material-ui/svg-icons/action/camera-enhance';
import ActionCheckCircle from 'material-ui/svg-icons/action/check-circle';
import ActionDateRange from 'material-ui/svg-icons/action/date-range';
import ActionDelete from 'material-ui/svg-icons/action/delete';
import ActionDeleteForever from 'material-ui/svg-icons/action/delete-forever';
import ActionHome from 'material-ui/svg-icons/action/home';
import ActionInfo from 'material-ui/svg-icons/action/info';
import ActionList from 'material-ui/svg-icons/action/list';
import ActionPowerSettingsNew from 'material-ui/svg-icons/action/power-settings-new';
import SettingsPower from 'material-ui/svg-icons/action/settings-power';
import ActionSearch from 'material-ui/svg-icons/action/search';
import ActionSettings from 'material-ui/svg-icons/action/settings';
import SettingsApplications from 'material-ui/svg-icons/action/settings-applications';
import SpeakerNotes from 'material-ui/svg-icons/action/speaker-notes';
import CreditCard from 'material-ui/svg-icons/action/credit-card';
import UploadInBrowser from 'material-ui/svg-icons/action/open-in-browser';
import Lock from 'material-ui/svg-icons/action/lock';
import LockOpen from 'material-ui/svg-icons/action/lock-open';
import Global from 'material-ui/svg-icons/action/language';
import Antenna from 'material-ui/svg-icons/action/settings-input-antenna';
import Favorite from 'material-ui/svg-icons/action/favorite';
import FavoriteBorder from 'material-ui/svg-icons/action/favorite-border';
import ThumbUp from 'material-ui/svg-icons/action/thumb-up';
import ThumbDown from 'material-ui/svg-icons/action/thumb-down';
import AvPause from 'material-ui/svg-icons/av/pause';
import AvPlayArrow from 'material-ui/svg-icons/av/play-arrow';
import SkipNext from 'material-ui/svg-icons/av/skip-next';
import SkipPrevious from 'material-ui/svg-icons/av/skip-previous';
import AvPlayCircleFilled from 'material-ui/svg-icons/av/play-circle-filled';
import AvPlayCircleOutline from 'material-ui/svg-icons/av/play-circle-outline';
import AvPauseCircleOutline from 'material-ui/svg-icons/av/pause-circle-outline';
import AddPages from 'material-ui/svg-icons/av/library-books';
import Attention from 'material-ui/svg-icons/av/new-releases';
import Loop from 'material-ui/svg-icons/av/loop';
import AlarmOn from 'material-ui/svg-icons/action/alarm-on';
import CommunicationImportContacts from 'material-ui/svg-icons/communication/import-contacts';
import ContentAdd from 'material-ui/svg-icons/content/add';
import Remove from 'material-ui/svg-icons/content/remove';
import AddCircle from 'material-ui/svg-icons/content/add-circle';
import ContentContentCopy from 'material-ui/svg-icons/content/content-copy';
import ContentLowPriority from 'material-ui/svg-icons/content/low-priority';
import ContentSave from 'material-ui/svg-icons/content/save';
import Send from 'material-ui/svg-icons/content/send';
import Help from 'material-ui/svg-icons/action/help';
import DeviceAccessTime from 'material-ui/svg-icons/device/access-time';
import EditorInsertPhoto from 'material-ui/svg-icons/editor/insert-photo';
import ImageEdit from 'material-ui/svg-icons/image/edit';
import ImagePictureAsPdf from 'material-ui/svg-icons/image/picture-as-pdf';
import Lens from 'material-ui/svg-icons/image/lens';
import Sensors from 'material-ui/svg-icons/image/tune';
import MapsPlace from 'material-ui/svg-icons/maps/place';
import PinDrop from 'material-ui/svg-icons/maps/pin-drop';
import Directions from 'material-ui/svg-icons/maps/directions';
import DirectionsRun from 'material-ui/svg-icons/maps/directions-run';
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';
import NotificationEventBusyNote from 'material-ui/svg-icons/notification/event-busy';
import NotificationEventNote from 'material-ui/svg-icons/notification/event-note';
import SocialGroup from 'material-ui/svg-icons/social/group';
import SocialLocationCity from 'material-ui/svg-icons/social/location-city';
import SocialPerson from 'material-ui/svg-icons/social/person';
import PersonOutline from 'material-ui/svg-icons/social/person-outline';
import Share from 'material-ui/svg-icons/social/share';
import Health from 'material-ui/svg-icons/social/cake';
import FileUpload from 'material-ui/svg-icons/file/file-upload';
import Reorder from 'material-ui/svg-icons/action/reorder';
import ChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import SettingsBackupRestore from 'material-ui/svg-icons/action/settings-backup-restore';
import Restore from 'material-ui/svg-icons/action/restore';
import WifiTethering from 'material-ui/svg-icons/device/wifi-tethering';
import Assignment from 'material-ui/svg-icons/action/announcement';
import Link from 'material-ui/svg-icons/content/link';
import Text from 'material-ui/svg-icons/content/font-download';
import Flag from 'material-ui/svg-icons/content/flag';
import CallSplit from 'material-ui/svg-icons/communication/call-split';
import Warning from 'material-ui/svg-icons/alert/warning';
import GateWay from 'material-ui/svg-icons/hardware/router';
import SwapVerticalCircle from 'material-ui/svg-icons/action/swap-vertical-circle';
import NotificationNone from 'material-ui/svg-icons/social/notifications-none';
import NotificationActive from 'material-ui/svg-icons/social/notifications-active';
import QueryBuilder from 'material-ui/svg-icons/action/query-builder';
import Alarm from 'material-ui/svg-icons/action/alarm';
import Update from 'material-ui/svg-icons/action/update';
import ErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import ReportProblem from 'material-ui/svg-icons/action/report-problem';
import GroupWork from 'material-ui/svg-icons/action/group-work';
import DeviceHub from 'material-ui/svg-icons/hardware/device-hub';
import LocationOn from 'material-ui/svg-icons/communication/location-on';
import VpnKey from 'material-ui/svg-icons/communication/vpn-key';
import Launch from 'material-ui/svg-icons/action/launch';
import PlaylistAdd from 'material-ui/svg-icons/av/playlist-add';
import PlaylistPlay from 'material-ui/svg-icons/av/playlist-play';
import Email from 'material-ui/svg-icons/communication/email';
import Chat from 'material-ui/svg-icons/communication/chat';
import Dialpad from 'material-ui/svg-icons/communication/dialpad';
import Traffic from 'material-ui/svg-icons/maps/traffic';
import KeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import KeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ContactMail from 'material-ui/svg-icons/communication/contact-mail';
import VerifiedUser from 'material-ui/svg-icons/action/verified-user';
import TrendingUp from 'material-ui/svg-icons/action/trending-up';
import Business from 'material-ui/svg-icons/communication/business';
import Check from 'material-ui/svg-icons/navigation/check';
import Build from 'material-ui/svg-icons/action/build';
import ImportExport from 'material-ui/svg-icons/communication/import-export';
import Dns from 'material-ui/svg-icons/action/dns';
import RemoveCircle from 'material-ui/svg-icons/content/remove-circle';
import FindReplace from 'material-ui/svg-icons/action/find-replace';
import Visibility from 'material-ui/svg-icons/action/visibility';
import ArrowDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import ArrowDropUp from 'material-ui/svg-icons/navigation/arrow-drop-up';
import GetApp from 'material-ui/svg-icons/action/get-app';
import Portrait from 'material-ui/svg-icons/image/portrait';
import ExitToApp from 'material-ui/svg-icons/action/exit-to-app';
import CloudDownloadIcon from 'material-ui/svg-icons/file/cloud-download';
import CheckBox from 'material-ui/svg-icons/toggle/check-box';
import FormatLineSpacing from 'material-ui/svg-icons/editor/format-line-spacing';
import VideoCamera from 'material-ui/svg-icons/av/videocam.js';
import Replay from 'material-ui/svg-icons/av/replay';
import GroupAdd from 'material-ui/svg-icons/social/group-add';
import Loyalty from 'material-ui/svg-icons/action/loyalty';
import UnfoldMore from 'material-ui/svg-icons/navigation/unfold-more';
import {
  MdOfflineBolt,
  MdAddPhotoAlternate,
  MdCollections,
  MdWarehouse,
  MdShoppingBasket,
  MdInsights,
} from 'react-icons/md';
import { HiBolt } from 'react-icons/hi2';
import { IoMdArrowDropleftCircle, IoMdArrowDroprightCircle, IoMdInformationCircle } from 'react-icons/io';
import { FaFilePen } from 'react-icons/fa6';
import { AiFillDollarCircle } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';

import Industry from '../Images/construction.svg';
import Pricing from '../Images/pricing.svg';
import QRCode from '../Images/qr_code.svg';
import Speed from '../Images/speed.svg';
import Upgrade from '../Images/upgrade.svg';
import Manufactoring from '../Images/manufacturing.svg';
import FastCheck from '../Images/fast-check.svg';
import OfflineBoltDarkSVG from '../Images/offline_bolt-dark.svg';
import OfflineBoltWhiteSVG from '../Images/offline_bolt-white.svg';
import OfflineBoltBlueSVG from '../Images/offline_bolt-blue.svg';
import OfflineBoltRedSVG from '../Images/offline_bolt-red.svg';
import AvailabilityOptions from '../Images/emoji_people_black.svg';
import SchedullingTriggersSVG from '../Images/pending_actions-24px.svg';
import BaseLineDateRangeSVG from '../Images/baseline-date_range-24px.svg';
import ElectricalServicesSVG from '../Images/electrical_services.svg';
import LocationSVG from '../Images/my_location_24dp.svg';
import TeamsWhiteSVG from '../Images/groups_white.svg';
import TeamsDarkSVG from '../Images/groups_dark.svg';
import TeamsBlueSVG from '../Images/groups_blue.svg';
import TeamsSecondaryBlueSVG from '../Images/groups_secondary_blue.svg';
import TeamsLightBlueSVG from '../Images/groups_light_blue.svg';
import TaskSVG from '../Images/task_black_24dp.svg';
import RunningWithErrorsSVG from '../Images/running_with_errors.svg';
import PastDueBacklogSVG from '../Images/work_history.svg';
import VimeoSVG from '../Images/vimeo.svg';
import UploadFileSVG from '../Images/upload_file_black.svg';
import ElectricBolt from '../Images/bolt.svg';
import Like from '../Images/thumb-up.svg';
import Dislike from '../Images/thumb-down.svg';
import AlternateCalendar from '../Images/calendar.svg';

export const CustomSVGIcon = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
`;

export const CustomSVGTeamsIcon = styled.img.attrs(({ src }) => ({
  src,
}))`
  fill: ${({ color }) => color || '#222333'};
  color: ${({ color }) => color || '#222333'};
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
`;

export const IndustryIcon = () => <CustomSVGIcon src={Industry} />;
export const PricingIcon = () => <CustomSVGIcon src={Pricing} />;
export const ExportQRIcon = props => <CustomSVGIcon src={QRCode} {...props} />;
export const MeteringIcon = () => <CustomSVGIcon src={Speed} />;
export const UpgradeIcon = () => <CustomSVGIcon src={Upgrade} />;
export const OEMIcon = () => <CustomSVGIcon src={Manufactoring} />;
export const DistributionIcon = () => <CustomSVGIcon src={FastCheck} />;
export const OfflineBoltDark = props => <CustomSVGIcon src={OfflineBoltDarkSVG} {...props} />;
export const OfflineBoltWhite = props => <CustomSVGIcon src={OfflineBoltWhiteSVG} {...props} />;
export const OfflineBoltBlue = props => <CustomSVGIcon src={OfflineBoltBlueSVG} {...props} />;
export const OfflineBoltRed = props => <CustomSVGIcon src={OfflineBoltRedSVG} {...props} />;
export const AvailabilityOptionsIcon = props => <CustomSVGIcon src={AvailabilityOptions} {...props} />;
export const SchedullingTriggers = props => <CustomSVGIcon src={SchedullingTriggersSVG} {...props} />;
export const ChecklistIcon = props => <CustomSVGIcon src={BaseLineDateRangeSVG} {...props} />;
export const ElectricalServices = props => <CustomSVGIcon src={ElectricalServicesSVG} {...props} />;
export const LocationIcon = props => <CustomSVGIcon src={LocationSVG} {...props} />;
export const TeamsWhite = props => <CustomSVGTeamsIcon src={TeamsWhiteSVG} {...props} />;
export const TeamsDark = props => <CustomSVGTeamsIcon src={TeamsDarkSVG} {...props} />;
export const TeamsBlue = props => <CustomSVGTeamsIcon src={TeamsBlueSVG} {...props} />;
export const TeamsSecondaryBlue = props => <CustomSVGTeamsIcon src={TeamsSecondaryBlueSVG} {...props} />;
export const TeamsLightBlue = props => <CustomSVGTeamsIcon src={TeamsLightBlueSVG} {...props} />;
export const TaskIcon = props => <CustomSVGTeamsIcon src={TaskSVG} {...props} />;
export const CheckboxIcon = props => <CustomSVGTeamsIcon src={TaskSVG} {...props} />;
export const RunningWithErrors = props => <CustomSVGTeamsIcon src={RunningWithErrorsSVG} {...props} />;
export const PastDueBacklog = props => <CustomSVGTeamsIcon src={PastDueBacklogSVG} {...props} />;
export const Vimeo = props => <CustomSVGTeamsIcon src={VimeoSVG} {...props} />;
export const UploadFile = props => <CustomSVGTeamsIcon src={UploadFileSVG} {...props} />;
export const ElectricBoltIcon = props => <CustomSVGIcon src={ElectricBolt} {...props} />;
export const LikeIcon = props => <CustomSVGIcon src={Like} {...props} />;
export const DislikeIcon = props => <CustomSVGIcon src={Dislike} {...props} />;
export const AlternateCalendarIcon = props => <CustomSVGIcon src={AlternateCalendar} {...props} />;

export const CallSplitIcon = styled(CallSplit)`
  transform: rotate(90deg);
`;
export const HelpIcon = styled(Help)``;
export const WifiIcon = styled(WifiTethering)``;
export const RestoreIcon = styled(SettingsBackupRestore)`
  align-self: center;
`;
export const RetrieveIcon = styled(Restore)``;
export const TextIcon = styled(Text)``;
export const FlagIcon = styled(Flag)``;
export const AssigmentIcon = styled(Assignment)``;
export const LinkIcon = styled(Link).attrs({
  color: ({ colored, theme }) => (colored === 'true' ? theme.primaryRed : 'inherit'),
})``;
export const AccessTimeIcon = styled(DeviceAccessTime)``;
export const AddIcon = styled(ContentAdd)``;
export const RemoveIcon = styled(Remove)``;
export const AddCircleIcon = styled(AddCircle).attrs({
  color: ({ theme, color }) => color || theme.darkGrey,
})`
  transform: ${({ isScaling = true }) => (isScaling ? 'scale(1.7)' : 'scale(1)')};
`;
export const RemoveCircleIcon = styled(RemoveCircle).attrs({
  color: ({ theme, color }) => color || theme.darkGrey,
})``;
export const ArrowBackIcon = styled(NavigationArrowBack)``;
export const ArrowForwardIcon = styled(ArrowBackIcon)`
  transform: rotate(180deg);
`;
export const CalendarIcon = styled(NotificationEventNote)``;
export const CalendarBusyIcon = styled(NotificationEventBusyNote)``;
export const CameraIcon = styled(ActionCameraEnhance)``;
export const CloseIcon = styled(NavigationClose)``;
export const CommitUnitIcon = styled(ActionAccountBalance)``;
export const CopyIcon = styled(ContentContentCopy)``;
export const DateRangeIcon = styled(ActionDateRange)``;
export const DeleteIcon = styled(ActionDelete)``;
export const DeleteForeverIcon = styled(ActionDeleteForever)``;
export const DocumentsModuleIcon = styled(CommunicationImportContacts)``;
export const EditIcon = styled(ImageEdit)``;
export const ExportCSVIcon = styled(ActionAssignmentReturned)``;
export const ExchangeModuleIcon = styled(ActionAssignmentReturned)``;
export const ResolvedTaskIcon = styled(AssignmentTurnedIn)``;
export const FileUploadIcon = styled(FileUpload)``;
export const GroupIcon = styled(SocialGroup)``;
export const HomeIcon = styled(ActionHome)``;
export const InfoIcon = styled(ActionInfo)``;
export const ImageIcon = styled(EditorInsertPhoto)``;
export const ImportIcon = styled(ActionAssignmentReturned)``;
export const InstructionsIcon = styled(CommunicationImportContacts)``;
export const ListIcon = styled(ActionList)``;
export const ListsModuleIcon = styled(ActionAssignment)``;
export const LowPriorityIcon = styled(ContentLowPriority)``;
export const MoreVerticalIcon = styled(NavigationMoreVert)``;
export const ChevronRightIcon = styled(ChevronRight)``;
export const CloudDownload = styled(CloudDownloadIcon)``;
export const CheckBoxIcon = styled(CheckBox)``;
export const LoyaltyIcon = styled(Loyalty)``;
export const UnfoldMoreIcon = styled(UnfoldMore)``;
export const FormatLineSpacingIcon = styled(FormatLineSpacing)`
  color: ${({ theme, color }) => theme.primaryGrey || color} !important;
`;
export const ChevronLeftIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;
export const BackIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg) scale(1.7);
`;
export const ForwardIcon = styled(ChevronRightIcon)`
  transform: scale(1.7);
`;
export const PauseIcon = styled(AvPause)``;
export const PlayIcon = styled(AvPlayArrow)``;
export const PlayIconFilled = styled(AvPlayCircleFilled)``;
export const PlayIconCircleOutline = styled(AvPlayCircleOutline)``;
export const NextIcon = styled(SkipNext)``;
export const PreviousIcon = styled(SkipPrevious)``;
export const LoopIcon = styled(Loop)`
  transform: scale(-1, 1);
`;
export const PauseCircleOutlineIcon = styled(AvPauseCircleOutline)``;
export const AlarmOnIcon = styled(AlarmOn)``;
export const AddPagesIcon = styled(AddPages)``;
export const AttentionIcon = styled(Attention)``;
export const PdfIcon = styled(ImagePictureAsPdf)``;
export const SpotIcon = styled(Lens)``;
export const SensorsIcon = styled(Sensors)``;
export const PersonIcon = styled(SocialPerson)``;
export const AccountsIcon = styled(SocialPerson)`
  border-radius: 50%;
  background-color: black;
`;
export const PersonOutlineIcon = styled(PersonOutline)``;
export const PeopleModuleIcon = styled(SocialGroup)``;
export const ShareIcon = styled(Share)``;
export const HealthIcon = styled(Health).attrs({
  color: ({ theme }) => theme.textGray,
})``;
export const PowerIcon = styled(ActionPowerSettingsNew)``;
export const DemoTasksIcon = styled(SettingsPower)``;
export const QueueModuleIcon = styled(ActionAssessment)``;
export const SaveIcon = styled(ContentSave)``;
export const SendIcon = styled(Send)``;
export const SchedulerModuleIcon = styled(ActionDateRange)``;
export const SearchIcon = styled(ActionSearch)``;
export const SettingsIcon = styled(ActionSettings)``;
export const SettingsWhitesIcon = styled(SettingsApplications)``;
export const NotesIcon = styled(SpeakerNotes)``;
export const CreditCardIcon = styled(CreditCard)``;
export const UploadInBrowserIcon = styled(UploadInBrowser)``;
export const LockIcon = styled(Lock).attrs({
  color: ({ theme }) => theme.primaryRed,
})``;
export const LockOpenIcon = styled(LockOpen)``;
export const GlobalIcon = styled(Global)``;
export const AntennaIcon = styled(Antenna)``;
export const FavoriteIcon = styled(Favorite)``;
export const FavoriteBorderIcon = styled(FavoriteBorder)``;
export const ThumbUpIcon = styled(ThumbUp)``;
export const ThumbDownIcon = styled(ThumbDown)``;
export const SitesModuleIcon = styled(MapsPlace)``;
export const PinDropIcon = styled(PinDrop)``;
export const DirectionsIcon = styled(Directions)``;
export const DirectionRunsIcon = styled(DirectionsRun)``;
export const TasksModuleIcon = styled(ActionCheckCircle)``;
export const TenantsModuleIcon = styled(SocialLocationCity)``;
export const DndIcon = styled(Reorder)``;
export const WarningIcon = styled(Warning)``;
export const GateWayIcon = styled(GateWay)``;
export const SwapVerticalCircleIcon = styled(SwapVerticalCircle)``;
export const NotificationOff = styled(NotificationNone)``;
export const NotificationOn = styled(NotificationActive).attrs({
  color: ({ theme }) => theme.primaryRed,
})``;
export const TimezoneIcon = styled(QueryBuilder)``;
export const SitesNotificationIcon = styled(Alarm)``;
export const UpdateIcon = styled(Update)`
  transform: rotate(180deg);
`;
export const NotificationInfoIcon = styled(ErrorOutline)``;
export const AttentionTasksIcon = styled(ReportProblem)``;
export const ActivatorIcon = styled(Attention).attrs({
  color: ({ theme }) => theme.textGray,
})``;
export const ResourceGroupsIcon = styled(GroupWork)``;
export const HubIcon = styled(DeviceHub)``;
export const Location = styled(LocationOn)``;
export const KeyIcon = styled(VpnKey)``;
export const LaunchIcon = styled(Launch)``;
export const AddSignUpNotification = styled(PlaylistAdd)``;
export const CreateSectionsIcon = styled(PlaylistPlay)``;
export const EnvelopeIcon = styled(Email)``;
export const ChatIcon = styled(Chat)``;
export const DialpadIcon = styled(Dialpad)``;
export const LimitsIcon = styled(Traffic)``;
export const ChevronUp = styled(KeyboardArrowUp)``;
export const ChevronDown = styled(KeyboardArrowDown)``;
export const ContactMailIcon = styled(ContactMail)``;
export const VerifiedUserIcon = styled(VerifiedUser)``;
export const TrendingUpIcon = styled(TrendingUp)``;
export const SuppliersIcon = styled(Business)``;
export const CheckIcon = styled(Check)``;
export const PartsIcon = styled(Build)``;
export const UpDownArrowIcon = styled(ImportExport)``;
export const OptionsIcon = styled(Dns)``;
export const FindReplaceIcon = styled(FindReplace).attrs({
  color: ({ color }) => color || 'inherit',
})``;
export const VisibilityIcon = styled(Visibility)``;
export const ArrowDropDownIcon = styled(ArrowDropDown)``;
export const ArrowDropUpIcon = styled(ArrowDropUp)``;
export const GetAppIcon = styled(GetApp)``;
export const PortraitIcon = styled(Portrait)``;
export const ExitToAppIcon = styled(ExitToApp)``;
export const TutorialsIcon = styled(VideoCamera)`
  color: ${({ theme, module }) => (module === 'Health' ? theme.textGray : theme.primaryWhite)} !important;,
`;
export const ReplayIcon = styled(Replay)``;
export const GroupAddIcon = styled(GroupAdd)``;
export const MdOfflineBoltIcon = styled(MdOfflineBolt)``;
export const HiBoltIcon = styled(HiBolt)``;
export const DropleftCircleIcon = styled(IoMdArrowDropleftCircle)``;
export const DroprightCircleIcon = styled(IoMdArrowDroprightCircle)``;
export const FilePenIcon = styled(FaFilePen)``;
export const AddPhotoIcon = styled(MdAddPhotoAlternate)``;
export const MonetizationOnIcon = styled(AiFillDollarCircle)``;
export const CollectionsIcon = styled(MdCollections)``;
export const InformationIcon = styled(IoMdInformationCircle)``;
export const ShoppingBasketIcon = styled(MdShoppingBasket)``;
export const InsightsIcon = styled(MdInsights)``;
export const LinkExternalIcon = styled(BiLinkExternal)``;
export const WarehouseIcon = styled(MdWarehouse)`
  color: ${({ theme }) => theme.primaryBlack};
  width: 24px;
  height: 24px;
`;
