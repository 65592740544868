import styled from 'styled-components';
import { Flex } from 'grid-styled';

const TitleStyle = {
  color: '#d50000',
};

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
`;

const ListContainer = styled(Flex).attrs({
  column: true,
  flex: '1',
})`
  background-color: ${({ theme }) => theme.lightGrey};
  overflow-y: auto;
`;

export { TitleStyle, ListContainer, ToggleContainer };
