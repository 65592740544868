import styled, { css } from 'styled-components';

const Container = styled.div`
  margin: 20px;
`;

const Name = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const addStyles = css`
  flex: 1;
`;

const styles1 = css`
  padding-left: 30px;
  min-width: 250px;
`;

const Item = styled.div`
  padding: 20px 20px 10px 10px;

  ${({ styles }) => styles};
`;

const TaskList = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
`;

const TaskHeader = styled.div`
  background-color: #c5dfe4;
  padding: 10px;
`;

const TaskRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  background: ${({ theme }) => theme.primaryWhite};
`;

const TaskTitle = styled.div`
  padding: 0 10px;
`;

const TaskInstruction = styled.div`
  word-break: break-word;
  white-space: normal;
`;

const TaskDate = styled.div`
  padding: 0 10px;
  text-align: right;
`;

export {
  Container,
  Name,
  Item,
  addStyles,
  styles1,
  TaskList,
  TaskHeader,
  TaskRow,
  TaskTitle,
  TaskInstruction,
  TaskDate,
};
