import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getSelectedSite } from 'helpers/storage';

import { getPdfReport } from 'http/queue';

import { openErrorDialog } from 'redux/errorHandler';
import { actions as unitsActions } from 'redux/units';
import { getUnitNameById } from 'redux/units/selectors';
import { actions as tasksActions } from 'redux/tasks';

import { actions as queueActions } from 'redux/queue';
import { actions as exportPdfReportActions } from 'redux/exportPdfReport';

import { BackIcon, DeleteIcon, ImportIcon } from 'components/Layout/Icons';
import Subheader from 'components/Subheader';
import EmptyList from 'components/EmptyList';
import Details from './Details';
import PdfReportDialog from './PdfReportDialog';
import TaskInfoDialog from './TaskInfoDialog';
import { ButtonIcon } from 'components/Layout/Buttons';
import BaseDialog from 'components/Dialogs/Base';
import TaskWithPartsDetails from './TaskWithPartsDetails';
import { Table } from './Table';

import { calculateRanges } from './helpers';

import * as S from './styled';

const UnitDetail = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { assigneeId, category, date, period, unitId } = useParams();
  const dispatch = useDispatch();
  const [pdfReportDialogOpened, setPdfReportDialogOpened] = useState(false);
  const [requiresAttention, setRequiresAttention] = useState(false);
  const [taskInfoDialogOpened, setTaskInfoDialogOpened] = useState(false);
  const [taskPhotoDialogOpened, setTaskPhotoDialogOpened] = useState(false);
  const [viewedTask, setViewedTask] = useState(null);
  const [dateSortingValue, setDateSortingValue] = useState('asc');
  const [tasks, setTasks] = useState([]);
  const [isPartsViewDialogOpen, setIsPartsViewDialogOpen] = useState(false);

  const error = useSelector(state => state.auth.error || state.queue.error || state.units.error);
  const notesPhotos = useSelector(state => state.tasks.taskNotes);
  const photos = useSelector(state => state.tasks.taskPhotos);
  const reportList = useSelector(state => state.exportPdfReport.reportList);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const tasksList = useSelector(state => state.queue.assignedTasks);
  const loading = useSelector(state => state.tasks.loading);
  const unitName = useSelector(state => getUnitNameById(state.units, unitId));
  const taskDetails = useSelector(state => state.tasks.taskDetails);
  const taskParts = useSelector(state =>
    state.tasks?.taskDetails?.AssignedTaskParts?.map(({ PartID }) => {
      const { MyPartID, MyPartName } = tasks?.taskDetails?.Parts.find(({ Id }) => Id === PartID);

      return { myPartId: MyPartID, myPartName: MyPartName };
    }),
  );

  const siteId = getSelectedSite();
  const isRequireAttentionTasks = period === 'all' || period === 'parts';
  const title = isRequireAttentionTasks ? 'Attention details' : 'Progress';
  const isUnassigned = category === 'All Tasks' ? null : category === 'Pick-Up' || category === 'Team';

  const defaultParams = {
    period,
    rangeStart: date,
    unitId,
    includeTeam: category === 'Team' || category === 'All Tasks',
    userId: assigneeId === 'allusers' ? '' : assigneeId,
    isTeamOnly: category === 'Team',
  };

  const onToggle = () => setRequiresAttention(!requiresAttention);
  const getPdf = item => getPdfReport({ id: item.Id });

  const deletePdf = item => dispatch(exportPdfReportActions.deletePdfReportFileRequest({ id: item.Id }));

  const goBack = () => {
    history.push(`/queue/${date}/${period}`);
  };

  const rightControlsForPdfList = item => (
    <div>
      <ButtonIcon onClick={() => deletePdf(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => getPdf(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  const exportReport = () => {
    dispatch(
      exportPdfReportActions.createPdfReportRequest({
        dates: calculateRanges(date, period),
        unitId,
        userId: assigneeId,
      }),
    );
    dispatch(
      openErrorDialog(
        'We are generating your report. When it will be ready you will get email notification',
        'Pdf reports',
      ),
    );
    setPdfReportDialogOpened(false);
  };

  const closePdfReportDialog = () => setPdfReportDialogOpened(false);

  const closeTaskInfoDialog = () => {
    setTaskInfoDialogOpened(false);
    setViewedTask(null);
  };

  const closeTaskPhotoDialog = () => {
    setTaskInfoDialogOpened(true);
    setTaskPhotoDialogOpened(false);
  };

  const openTaskPhotoDialog = item => {
    dispatch(tasksActions.fetchPhotosRequest(item.ItemId));
    setTaskPhotoDialogOpened(true);
  };

  const onChangeDateSorting = newDateSortingValue => {
    const sortedTasks = orderBy(tasks, task => moment(task.ExecutionDate).format('MM/DD/YYYY'), [dateSortingValue]);

    setDateSortingValue(newDateSortingValue);
    setTasks(sortedTasks);
  };

  const closePartsViewDialog = () => setIsPartsViewDialogOpen(false);

  const updateAssignedTaskList = () => {
    dispatch(
      queueActions.fetchAssignedTasksRequest({
        ...defaultParams,
        isUnassigned,
        requiresAttention,
        requiredAttetionParts: period === 'parts' || null,
      }),
    );
    closePartsViewDialog();
  };

  useEffect(() => {
    const allTasksParams = {
      ...defaultParams,
      includeCompleted: true,
      isUnassigned,
      requiresAttention,
      requiredAttetionParts: period === 'parts' || null,
    };

    dispatch(unitsActions.unitsListRequest({ filter: { SiteID: selectedSite } }));
    dispatch(queueActions.fetchAssignedTasksRequest(allTasksParams));

    return () => {
      setTasks([]);
      setDateSortingValue('asc');
      dispatch(queueActions.resetAssignedTasks());
      dispatch(queueActions.setSelectedTasksInfo({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeId, requiresAttention, category, date, pathname, period, selectedSite, unitId]);

  useEffect(() => {
    if (period === 'all' || period === 'parts') {
      setTasks(tasksList);
    }
  }, [tasksList, period]);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error, 'Error'));
      dispatch(queueActions.stateErrorClear());
    }
  }, [dispatch, error]);

  return [
    <Subheader
      key="UnitDetail Subheader"
      title={title}
      titleStyle={S.TitleStyle}
      isSiteOnLeftSide
      leftButtons={[
        {
          icon: <BackIcon />,
          handler: goBack,
          hint: 'Back',
        },
      ]}
    />,
    <Details
      key="Unit Details"
      data={{
        assigneeName: category,
        period,
        rangeStart: date,
        isRequireAttentionTasks,
        requireAttentionTasksTotal: tasks.length,
        dateSortingValue,
        unitName,
      }}
      onToggle={onToggle}
      onChangeDateSorting={onChangeDateSorting}
    />,
    <S.ListContainer key="Tasks list">
      {!loading && (!!tasks.length || !!tasksList.length) ? (
        <Table tasksList={tasksList} category={category} />
      ) : (
        <EmptyList text="There are no tasks" />
      )}
    </S.ListContainer>,
    <TaskInfoDialog
      key="TaskInfoDialog"
      title={viewedTask && `Task Result Details: ${viewedTask.RuleType}`}
      item={viewedTask}
      open={taskInfoDialogOpened}
      handleClose={closeTaskInfoDialog}
      openDialog={taskPhotoDialogOpened}
      openPhotoDialog={openTaskPhotoDialog}
      closePhotoDialog={closeTaskPhotoDialog}
      notesPhotos={notesPhotos}
      photos={photos}
      siteId={siteId}
    />,
    <PdfReportDialog
      key="Date Range Dialog"
      title="PDF Reports"
      handleClose={closePdfReportDialog}
      onSubmit={exportReport}
      open={pdfReportDialogOpened}
      pdfList={reportList}
      rightControls={rightControlsForPdfList}
    />,
    <BaseDialog
      key="items part preview"
      open={isPartsViewDialogOpen}
      onRequestClose={closePartsViewDialog}
      title={'Item View'}
    >
      <TaskWithPartsDetails
        {...taskDetails}
        isDialogWithParts={false}
        taskParts={taskParts}
        notesPhotos={notesPhotos}
        updateAssignedTaskList={updateAssignedTaskList}
      />
    </BaseDialog>,
  ];
};

export default UnitDetail;
