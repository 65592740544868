import moment from 'moment';

const calculateRanges = (date, period) => {
  const rangesMap = {
    day: {
      startPeriod: moment(date).format('MM/DD/YYYY'),
      finishPeriod: moment(date).format('MM/DD/YYYY'),
    },
    week: {
      startPeriod: moment(date).format('MM/DD/YYYY'),
      finishPeriod: moment(date).add(1, 'week').format('MM/DD/YYYY'),
    },
    past: {
      finishPeriod: moment().subtract(1, 'day').format('MM/DD/YYYY'),
    },
  };

  return rangesMap[period];
};

export { calculateRanges };
