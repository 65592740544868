import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import Checkbox from 'material-ui/Checkbox';
import MenuItem from 'material-ui/MenuItem';
import { ButtonIcon } from 'components/Layout/Buttons';

import { FieldSelect } from 'components/Layout/Selects';
import { InputBlockLabel } from 'components/Layout/Containers';

export const Container = styled(Flex).attrs({
  column: true,
})`
  z-index: 1;
`;

export const TextBlock = styled(Flex).attrs({
  column: true,
  width: 49 / 50,
})``;

export const ButtonBlock = styled(Flex).attrs({
  column: true,
  width: 1 / 50,
})``;

export const InfoItem = styled(Box)``;

export const CheckBoxButton = styled(Checkbox).attrs({
  labelStyle: {
    color: 'red',
  },
})`
  padding-left: 36px;
  padding-bottom: 10px;
`;

export const Select = styled(FieldSelect).attrs({
  style: { width: 180 },
  underlineStyle: { display: 'none' },
  labelStyle: { left: '20px' },
})`
  align-self: flex-end;
`;

export const Label = styled(InputBlockLabel)`
  background-color: ${({ theme }) => theme.secondaryGreen};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TextContainer = styled.div`
  padding: 12px;
`;

export const AllTasks = styled.div`
  font-weight: bold;
  color: #09786e; // ${({ theme }) => theme.secondaryGreen};
`;

export const Progress = styled.div`
  padding-left: 28px;
`;

export const IconButton = styled(ButtonIcon).attrs({
  iconStyle: ({ theme }) => ({
    color: theme.primaryWhite,
  }),
})`
  word-wrap: normal;
  word-break: normal;
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;

  &:hover {
    transform: translateZ(0) scale(1);
  }
`;

export const UnitName = styled.div`
  flex: 1;
`;

export const Option = styled(MenuItem)``;

export const AssigneeName = styled.p`
  margin-left: 70px;
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.mainRed};
`;

export const View = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid #dddddd;
`;

export const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryBlack};
`;
