export const formatTriggers = list => {
  const triggersByUserId = {};

  list.forEach(trigger => {
    if (!triggersByUserId[trigger.TriggeredByUserId]) {
      triggersByUserId[trigger.TriggeredByUserId] = {};
    }

    if (!triggersByUserId[trigger.TriggeredByUserId][trigger.EventSourceType]) {
      triggersByUserId[trigger.TriggeredByUserId][trigger.EventSourceType] = [];
    }

    triggersByUserId[trigger.TriggeredByUserId][trigger.EventSourceType].push(trigger);
  });

  const userIds = Object.keys(triggersByUserId);

  return { triggersByUserId, userIds };
};

const sortByAssigneeName = list => {
  const priorityOrder = ['All Tasks', 'Quick Tasks', 'Pick-Up'];

  const sortedList = list.sort((a, b) => {
    const nameA = a.AssigneeName;
    const nameB = b.AssigneeName;

    const indexA = priorityOrder.indexOf(nameA);
    const indexB = priorityOrder.indexOf(nameB);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    return nameA.localeCompare(nameB);
  });

  return sortedList;
};

export const normalizeByUnitName = list => {
  const entities = {};
  const ids = [];
  const sortedList = sortByAssigneeName(list);

  sortedList.forEach(item => {
    if (item.Id) {
      ids.push(item.Id);
    } else if (item.AssigneeName === 'All Tasks') {
      ids.push(item.TasksTotal);
    }
    if (!entities[item.UnitName]) {
      entities[item.UnitName] = [];
    }

    entities[item.UnitName].push(item);
  });

  const keys = Object.keys(entities);

  return { entities, keys, ids };
};

export const changeAssigneeName = list =>
  list.map(item => {
    if (item.AssigneeName === 'Unassigned') {
      return { ...item, AssigneeName: 'Pick-Up' };
    }

    return item;
  });
