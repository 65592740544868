import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import moment from 'moment';

import DropdownMenu from 'components/DropdownMenu';
import Subheader from 'components/Subheader';
import TableList from 'components/TableList';
import ColumnVisibility from 'components/ColumnVisibility';

import { BackIcon, AddSignUpNotification, CloudDownload, ImportIcon, DeleteIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import { actions as queueActions } from 'redux/queue';
import { convertDateToThreeDigit, searchItemByKeyword } from 'helpers';

import { WITHOUT_GROUPING } from '../ReportSetup/ReportGrouping/helpers';
import GroupedTable from './components/Table';
import DownloadReportDialog from './DownloadReportDialog';
import { TABLE_COLUMNS, DOWNLOAD_MENU_ITEMS } from './constants';

import * as S from './styled';

const ReportPreview = () => {
  const [searchData, setSearchData] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableColumns, setTableColumns] = useState(TABLE_COLUMNS);

  const reportList = useSelector(state => state.queue.reportList);
  const reportPreview = useSelector(state => state.queue.reportPreview);
  let report = useSelector(state => state.queue.report);

  const dispatch = useDispatch();

  const history = useHistory();
  const { reportId } = useParams();
  const reportDownloadModal = useModal(DownloadReportDialog);

  if (!report) {
    report = reportList.find(({ Id }) => Id === reportId);
  }

  const { Name, GroupBy, SubGroupBy, EndDate, StartDate } = report || {};
  const withGrouping = GroupBy !== WITHOUT_GROUPING && SubGroupBy !== WITHOUT_GROUPING;

  const goBack = () => history.push('/queue/export');

  const openDownloadReportFile = (e, value) => {
    if (value === 'pdf') {
      downloadReport(value);
    } else {
      dispatch(queueActions.fetchListFileReportsRequest({ id: reportId, fileFormat: value }));
      reportDownloadModal.show({
        key: 'Date Range Dialog',
        endDate: moment(EndDate).format('ddd, MMM-DD-YYYY'),
        startDate: moment(StartDate).format('ddd, MMM-DD-YYYY'),
        onSubmit: () => downloadReport(value),
        rightControls,
        redTitle: Name,
      });
    }
  };

  const rightButtons = [
    {
      component: (
        <DropdownMenu
          onChange={openDownloadReportFile}
          headerText="Download"
          items={DOWNLOAD_MENU_ITEMS}
          icon={<CloudDownload />}
        />
      ),
      hint: 'Downloads',
      isComponent: true,
    },
    {
      icon: <AddSignUpNotification />,
      hint: 'Columns',
      handler: e => openModal(e),
    },
  ];

  const leftButtons = [
    {
      icon: <BackIcon />,
      handler: goBack,
      hint: 'Back',
    },
  ];

  const mapList = item => ({
    ...item,
    CompletedOn: convertDateToThreeDigit(item.CompletedOn),
    ExecutionDate: convertDateToThreeDigit(item.CompletedOn),
  });

  const searchInList = e => setSearchData(e.target.value);

  const openModal = e => {
    setIsOpenModal(true);
    setAnchorEl(e.currentTarget);
  };

  const closeModal = () => setIsOpenModal(false);

  const setCheckedLabel = checkedLabel => setTableColumns(checkedLabel);

  const deleteReportFile = item => dispatch(queueActions.deleteReportFileRequest({ id: item.Id }));

  const downloadReportFile = item => {
    dispatch(queueActions.downloadReportFileRequest({ fileId: item.Id }));
  };

  const rightControls = item => (
    <div>
      <ButtonIcon onClick={() => deleteReportFile(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => downloadReportFile(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  const downloadReport = fileFormat => {
    dispatch(queueActions.generateAndDownloadReportFileRequest({ id: reportId, fileFormat }));
  };

  useEffect(() => {
    const tableConfigs = JSON.parse(localStorage.getItem('reportPreviewTableConfigs'));
    if (tableConfigs) {
      setTableColumns(tableConfigs);
    }

    dispatch(queueActions.fetchReportPreviewRequest(reportId));
  }, []);

  return (
    <>
      <Subheader
        leftButtons={leftButtons}
        isSiteOnLeftSide
        rightButtons={rightButtons}
        withBorder
        title="Task Reports"
        isSearch
        searchData={searchData}
        searchInList={searchInList}
        redSubtitle={Name}
      />
      <ColumnVisibility
        labels={tableColumns}
        setSelectedLabel={setCheckedLabel}
        anchorEl={anchorEl}
        open={isOpenModal}
        handleRequestClose={closeModal}
      />

      {/* eslint-disable-next-line no-nested-ternary */}
      {!reportPreview?.length ? (
        <S.EmptyList>
          <S.EmptyListText>There is no data available to create a report.</S.EmptyListText>
          <S.EmptyListText>Please adjust the rules and try again.</S.EmptyListText>
        </S.EmptyList>
      ) : withGrouping ? (
        <GroupedTable
          list={reportPreview}
          tableColumns={tableColumns}
          tableHeads={tableColumns}
          searchData={searchData}
        />
      ) : (
        <S.TableContainer>
          <TableList
            list={reportPreview.map(mapList).filter(searchItemByKeyword(tableColumns, searchData))}
            tableColumns={tableColumns}
            tableHeads={tableColumns}
          />
        </S.TableContainer>
      )}
    </>
  );
};

export default ReportPreview;
