import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import SimpleList from 'components/SimpleList';
import { ActionButton } from 'components/Dialogs/v1/Base';
import PdfItem from './PdfItem';
import { findSite } from 'helpers';

import * as S from './styled';

const DownloadReportDialog = NiceModal.create(({ endDate, onSubmit, startDate, rightControls, redTitle }) => {
  const reportFilesList = useSelector(state => state.queue.reportFilesList);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const sitesAvailableToUserFullInfo = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const modal = useModal();

  const site = findSite(sitesAvailableToUserFullInfo, selectedSite);

  const onDownload = () => {
    onSubmit();
    modal.hide();
  };

  return (
    <S.DialogContainer
      actions={[
        <ActionButton type="button" label="Cancel" onClick={modal.hide} />,
        <ActionButton type="button" onClick={onDownload} isNext label="NEW Download" />,
      ]}
      onRequestClose={modal.hide}
      open={modal.visible}
      title="Export Spreadsheet"
    >
      <>
        <S.Title>{site}</S.Title>
        <S.RedTitle>{redTitle}</S.RedTitle>
        <S.DateContainer>
          <S.TextDate>From: {startDate}</S.TextDate>
          <S.TextDate>To: {endDate}</S.TextDate>
        </S.DateContainer>
        <SimpleList
          key="Report List"
          data={reportFilesList}
          renderItemContent={PdfItem}
          renderRightControls={rightControls}
        />
      </>
    </S.DialogContainer>
  );
});

DownloadReportDialog.defaultProps = {
  endDate: null,
  startDate: null,
};

DownloadReportDialog.propTypes = {
  title: PropTypes.string.isRequired,
  endDate: PropTypes.instanceOf(Date),
  onSubmit: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  list: PropTypes.array.isRequired,
  rightControls: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  redTitle: PropTypes.string.isRequired,
};

export default DownloadReportDialog;
